;
(function($){

    // Alert subscriptions managment
    $(document).ready(function() {
        var newsletterState = $('.block-newsletter .group-switch-checkbox'),
            subscripState = $('.block-notification .group-switch-checkbox'),
            multiSelect = $('.block-notification .mutliSelect');

        var subscripCheck = function(all=true) {
            $.ajax({
                url: '/moncompte/mes-alertes/' + ((all) ? 'add' : 'remove') + '?theme=all',
            }).done(function() {
                multiSelect.find('input[type="checkbox"]:checked').each(function() {
                    $.ajax({
                        url: '/moncompte/mes-alertes/' + ((all) ? 'remove' : 'add') + '?theme=' + $(this).val(),
                    });
                });
            });
        };

        newsletterState.on('click', function() {
            if (!$(this).is(':checked')) {
                $.ajax({
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    url: '/api/sendinblue/unsubscribe',
                    method: 'POST',
                    dataType: 'json',
                    data: JSON.stringify({
                        list_id: Number($('.block-newsletter form.newsletter input[name="lists"]').val()),
                        email: $('.block-newsletter form.newsletter input[name="email"]').val().toLowerCase(),
                    })
                });
            } else {
                $.ajax({
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    url: '/api/sendinblue/add-contact',
                    method: 'POST',
                    dataType: 'json',
                    data: JSON.stringify({
                        lists: [Number($('.block-newsletter form.newsletter input[name="lists"]').val())],
                        email: $('.block-newsletter form.newsletter input[name="email"]').val().toLowerCase(),
                        is_bot: false,
                    })
                });
            }
        });

        subscripState.on('click', function() {
            if (!$(this).is(':checked')) {
                $.ajax({
                    url: '/moncompte/mes-alertes/remove?theme=all'
                });

                multiSelect.find('input[type="checkbox"]').prop('checked', false);
                $('.block-notification .multiSel').html('');
                $('.block-notification .placeholder-text').show();
            } else {
                $.ajax({
                    url: '/moncompte/mes-alertes/add?theme=all'
                });

                multiSelect.find('input[type="checkbox"]').prop('checked', true);
            }
        });

        multiSelect.find('input[type="checkbox"]').on('click', function() {
            if ($(this).is(':checked')) {
                if (multiSelect.find('input[type="checkbox"]').length === multiSelect.find('input[type="checkbox"]:checked').length) {
                    subscripCheck();
                } else {
                    $.ajax({
                        url: '/moncompte/mes-alertes/add?theme=' + $(this).val()
                    });
                }
            } else {
                if (multiSelect.find('input[type="checkbox"]').length - multiSelect.find('input[type="checkbox"]:checked').length === 1) {
                    subscripCheck(false);
                }

                $.ajax({
                    url: '/moncompte/mes-alertes/remove?theme=' + $(this).val()
                });
            }

            subscripState.prop('checked', 0 < multiSelect.find('input[type="checkbox"]:checked').length);
        });
    });

})(jQuery);